import styled, { css } from 'styled-components';
import { InOutCardResponsive } from '../../../../../components';
import { device } from '../../../../../utils/device';

const ContentWrapper = styled.div`
  margin: 5px 10px;

  ${
    p => p.multipleContracts &&
    css`
      width: 45%;

      @media ${device.tablet} {
        width: 100%;
      }
    `
  }
`;

const CardWrapper = styled(InOutCardResponsive)`
  width: 60%;
  padding: 16px;
  border-radius: 5px;

  @media ${device.tablet} {
    width: 100%;
  }

  ${
    p => p.multipleContracts &&
    css`
      width: 100%;
    `
  }
`;

const CardTitleWrapper = styled.div`
  display: flex;
`;

const CardTitleText = styled.div`
  margin-left: 5px;
  color: #2D1441;
  font-weight: bold;
  flex-grow: 1;
`;

const DownloadIconWrapper = styled.div`
  align-self: flex-end;
  cursor: pointer;
`;

const CardInfoWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 30px;
  font-size: 12px;
  color: #827490;
  flex-wrap: wrap;

  @media ${device.mobileM} {
    flex-wrap: wrap;
  }
`;

const DateWrapper = styled.div`
  margin-right: 25px;
`;

const DateText = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

const FileSizeWrapper = styled.div``;

const FileSizeText = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

export {
    ContentWrapper,
    CardWrapper,
    CardTitleWrapper,
    CardTitleText,
    DownloadIconWrapper,
    CardInfoWrapper,
    DateWrapper,
    DateText,
    FileSizeWrapper,
    FileSizeText
};
