import React from 'react';
import { ContractService, AuthService } from '../../../../../services';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as DownloadIcon } from '../../../../../assets/images/download.svg';
import { ReactComponent as DocumentIcon } from '../../../../../assets/images/document.svg';
import {
  ContentWrapper,
  CardWrapper,
  CardTitleWrapper,
  CardTitleText,
  DownloadIconWrapper,
  CardInfoWrapper,
  DateWrapper,
  DateText,
  FileSizeWrapper,
  FileSizeText,
} from './styled';

const ContratosList = ({
  contracts,
  areMultipleContracts,
  downloadContractOrAnexPress,
}) => (
    <>
      {contracts.map((contract, index) => {
        return (
          <ContentWrapper
            key={uuidv4()}
            multipleContracts={areMultipleContracts}
          >
            <CardWrapper multipleContracts={areMultipleContracts}>
              <CardTitleWrapper>
                <DocumentIcon />
                <CardTitleText>{`Anexo N° ${index + 1}`}</CardTitleText>
                <DownloadIconWrapper
                  onClick={() =>
                    downloadContractOrAnexPress(
                      contract.id,
                      contract.name,
                      contract.extension
                    )
                  }
                >
                  <DownloadIcon />
                </DownloadIconWrapper>
              </CardTitleWrapper>
              <CardInfoWrapper>
                <DateWrapper>
                  <DateText>Fecha:</DateText>
                  {contract.creationDate}
                </DateWrapper>
                <FileSizeWrapper>
                  <FileSizeText>Peso:</FileSizeText>
                  {`${contract.size}KB`}
                </FileSizeWrapper>
              </CardInfoWrapper>
            </CardWrapper>
          </ContentWrapper>
        );
      })}
    </>
  );

export default ContratosList;
