import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Text } from '@womcorp/wom-ui-kit';
import { rem } from 'polished';
import { InOutCardResponsive } from '../../../..';
import { device } from '../../../../../utils/device';
import { ReactComponent as Ghost } from '../../../../../assets/images/ghost.svg';

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;

  @media ${device.laptop} {
    width: 100%;
  }
  h1,
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const CustomCardWrapper = styled(InOutCardResponsive)`
  max-width: 100%;
  margin-top: 0;
  padding-bottom: ${rem('36px')};

  @media ${device.tablet} {
    max-width: ${rem('470px')};
  }
`;

const CenteredText = styled(Text)`
  text-align: center;
  margin: 0;
`;

const GhostImgWrapper = styled.div`
  margin: 30px;
  text-align: center;
`;

const CustomButtonWrapper = styled.div`
  width: ${rem('300px')};
  margin: 0 auto;
  margin-top: ${rem('20px')};
  max-width: 85%;
`;

const ButtonText = styled.div`
  color: white;
  font-weight: normal;
`;

const CenteredTextBold = styled(CenteredText)`
  margin-bottom: ${rem('16px')};
  font-weight: bold;
  font-size: 20px;
`;

const CardTitle = styled.h4`
  ${
    p => p.desktop &&
    css`
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;

        @media ${device.tablet} {
          display: none;
        }
    `
    }

  ${
    p => p.mobile &&
    css`
    display: none;

    @media ${device.tablet} {
        display: flex;
        margin: 20px 0 20px 0;
        font-size: 20px;
        font-weight: 500;
    }
    `
    }
`;

const ArrowWrapper = styled.div`
  margin-right: 10px;
`;

const ContratosError = ({ onRetry }) => {
    return (
        <>
          <CardTitle desktop>Contratos y anexos</CardTitle>
          <CardTitle mobile>
            <ArrowWrapper>
              {`< `}
            </ArrowWrapper>
            | Contratos y anexos
          </CardTitle>
          <AccountWrapper>
            <CustomCardWrapper>
              <GhostImgWrapper>
                <Ghost />
              </GhostImgWrapper>
              <CenteredTextBold>No disponible</CenteredTextBold>
              <CenteredText>
                Algo fallo, los documentos no pudieron ser cargados.
              </CenteredText>
              <CenteredText>Vuelve a intentarlo más tarde.</CenteredText>
              <CustomButtonWrapper>
                <Button variant="primary" onClick={() => onRetry()} size="l" full>
                  <ButtonText>REINTENTAR</ButtonText>
                </Button>
              </CustomButtonWrapper>
            </CustomCardWrapper>
          </AccountWrapper>
        </>
    );
};

export default ContratosError;
