import React from 'react';
import { navigate } from 'gatsby';
import { ReactComponent as FindDocumentIcon } from '../../../../../assets/images/find-document.svg';
import {
  CardWrapper,
  CardTitle,
  ArrowWrapper,
  IconWrapper,
  DocumentTitle,
  DocumentText,
  InicioButton
} from './styled';

const handleClick = () => {
    navigate('/home/');
};

const ContratosNotFound = () => {
    return (
      <>
        <CardTitle desktop>Contratos y anexos</CardTitle>
        <CardTitle mobile>
          <ArrowWrapper>
            {`< `}
          </ArrowWrapper>
            | Contratos y anexos
        </CardTitle>
        <CardWrapper>
          <IconWrapper>
            <FindDocumentIcon />
          </IconWrapper>
          <DocumentTitle>No hay documentos</DocumentTitle>
          <DocumentText>Por el momento no se encuentran documentos</DocumentText>
          <InicioButton onClick={() => handleClick()} mobile>Volver al inicio</InicioButton>
        </CardWrapper>
      </>
    );
};

export default ContratosNotFound;
