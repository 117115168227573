import styled, { css } from 'styled-components';
import { InOutCardResponsive } from '../../../..';
import { device } from '../../../../../utils/device';
import { Button } from '@womcorp/wom-ui-kit';

const CardWrapper = styled(InOutCardResponsive)`
  margin-top: 0;
  color: #2D1441;
  padding: 20px;
`;

const CardTitle = styled.h4`
  ${
    p => p.desktop &&
    css`
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;

        @media ${device.tablet} {
          display: none;
        }
    `
    }

  ${
    p => p.mobile &&
    css`
    display: none;

    @media ${device.tablet} {
        display: flex;
        margin: 20px 0 20px 0;
        font-size: 20px;
        font-weight: 500;
    }
    `
    }
`;

const ArrowWrapper = styled.div`
  margin-right: 10px;
`;

const IconWrapper = styled.div`
  margin: 20px 0;
  text-align: center;
`;

const DocumentTitle = styled.div`
  margin: 10px 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
`;

const DocumentText = styled.div`
  margin: 10px 0;
  text-align: center;
`;

const InicioButton = styled(Button)`
  margin-top: 30px;
  margin-bottom: 20px;
  background-color: #E92070;
  border: 1px solid;
  border-radius: 6px;
  color: #FFFFFF;
  width: 50%;
  align-self: center;
  text-transfrom: uppercase;

  ${
    p => p.mobile &&
    css`
    @media ${device.tablet} {
      width: 90%;
    }
    `
    }
`;

export {
    CardWrapper,
    CardTitle,
    ArrowWrapper,
    IconWrapper,
    DocumentTitle,
    DocumentText,
    InicioButton
};
