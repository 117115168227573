import React, { useEffect, useState } from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import SEO from '../../../components/seo';
import ContratosYAnexos from '../../../components/pages/home/Contratos/ContratosAnexos';
import ContratosNotFound from '../../../components/pages/home/Contratos/ContratosNotFound/ContratosNotFound';
import ContratosError from '../../../components/pages/home/Contratos/ContratosError/ContratosError';
import Loading from '../../../components/Loading/Loading';
import {
  AccountService,
  ContractService,
} from '../../../services';
import { SiteLayout } from '../../../components/Layouts';
import { InOutCardResponsive } from '../../../components';
import { device } from '../../../utils/device';

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  @media ${device.laptop} {
    width: 100%;
  }
  h1,
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const CustomLoadingCardWrapper = styled(InOutCardResponsive)`
  max-width: 100%;
  margin-top: 0;
  @media ${device.tablet} {
    max-width: ${rem('470px')};
  }
`;

const Contratos = () => {
  const userName = AccountService.getUserName();
  const [contractData, setContractData] = useState([]);
  const [contractEmpty, setContractEmpty] = useState();
  const [isLoadingContratos, setIsLoadingContratos] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchContracts = async () => {
    setIsLoadingContratos(true);

    try {
      const [responseService] = await ContractService.getContractsData();

      if (responseService && responseService.documents.length !== 0) {
        setContractData(responseService);
        setContractEmpty(false);
      } else {
        setContractEmpty(true);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoadingContratos(false);
    }
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  return (
    <>
      <SEO title="Contratos y anexos" />
      <SiteLayout user={userName} mobileWithoutName>
        {isLoadingContratos && (
          <>
            <LoadingWrapper>
              <CustomLoadingCardWrapper>
                <Loading />
              </CustomLoadingCardWrapper>
            </LoadingWrapper>
          </>
        )}
        {!isLoadingContratos && !isError && !contractEmpty && (
          <ContratosYAnexos contracts={contractData} />
        )}
        {!isLoadingContratos && !isError && contractEmpty && (
          <ContratosNotFound />
        )}
        {!isLoadingContratos && isError && (
          <ContratosError onRetry={fetchContracts} />
        )}
      </SiteLayout>
    </>
  );
};

export default Contratos;
