import styled, { css } from 'styled-components';
import { InOutCardResponsive } from '../../../../components';
import { Button } from '@womcorp/wom-ui-kit';
import { device } from '../../../../utils/device';

const CardWrapper = styled(InOutCardResponsive)`
  margin-top: 0;
  color: #2D1441;
  padding: 20px;
`;

const CardContentWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  ${
    p => p.multipleContracts &&
    css`
      display: flex;
      justify-content: ${p.three || p.five ? 'flex-start' : 'center'};
      flex-wrap: wrap;
    `
  }
`;

const CardTitle = styled.h4`
  ${
    p => p.desktop &&
    css`
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 500;

      @media ${device.tablet} {
        display: none;
      }
    `
  }

  ${
  p => p.mobile &&
  css`
    display: none;

    @media ${device.tablet} {
      display: flex;
      margin: 20px 0 20px 0;
      font-size: 20px;
      font-weight: 500;
    }
    `
  }
`;

const ArrowWrapper = styled.div`
  margin-right: 10px;
`;

const TitleText = styled.p`
  color: #2D1441;
  text-align: left;
  font-size: 24px;
  font-weight: bold;
`;

const ContentDivider = styled.div`
  border: 1px dashed #CCC4D2;
  width: 100%;
`;

const InicioButton = styled(Button)`
  margin-top: 50px;
  margin-bottom: 20px;
  background-color: #E92070;
  border: 1px solid;
  border-radius: 6px;
  color: #FFFFFF;
  width: 50%;
  align-self: center;
  text-transfrom: uppercase;

  ${
    p => p.mobile &&
    css`
    @media ${device.tablet} {
      width: 100%;
    }
    `
    }
`;

export {
    CardWrapper,
    CardContentWrapper,
    CardTitle,
    ArrowWrapper,
    TitleText,
    ContentDivider,
    InicioButton
};
