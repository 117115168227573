import React from 'react';
import { navigate } from 'gatsby';
import JSZip from 'jszip';
import ContratosList from './ContratosList/ContratosList';

import {
  CardWrapper,
  CardContentWrapper,
  CardTitle,
  ArrowWrapper,
  TitleText,
  ContentDivider,
  InicioButton,
} from './styled';

// Services
import { ContractService } from '../../../../services';

const handleClick = () => {
  navigate('/home/');
};

const ContratosYAnexos = ({ contracts }) => {

  const documents = contracts.documents || [];
  const areMultipleContracts = documents.length >= 2;
  const areThreeContracts = documents.length === 3;
  const areFiveContracts = documents.length === 5;

  const onDownloadContractOrAnexPress = async (id, documentName, ext) => {
    const [response] = await ContractService.getContractsByID(
      id,
      documentName,
      ext
    );

    const { asBase64, name, mime, extension } = response.document;

    const byteCharacters = atob(asBase64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: mime });
    const downloadLink = document.createElement('a');
    const fileName = name;

    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${fileName}.${extension}`;
    downloadLink.click();

    /**
     * Remove the transitory link (sanitization)
     */
    downloadLink.remove();
  };

  return (
    <>
      <CardTitle desktop>Contratos y anexos</CardTitle>
      <CardTitle mobile>
        <ArrowWrapper>{`< `}</ArrowWrapper>| Contratos y anexos
      </CardTitle>
      <CardWrapper>
        <TitleText>Listado de documentos</TitleText>
        <ContentDivider />
        <CardContentWrapper
          multipleContracts={areMultipleContracts}
          three={areThreeContracts}
          five={areFiveContracts}
        >
          <ContratosList
            contracts={contracts.documents || []}
            areMultipleContracts={areMultipleContracts}
            downloadContractOrAnexPress={(id, name, extension) =>
              onDownloadContractOrAnexPress(id, name, extension)
            }
          />
        </CardContentWrapper>
        <InicioButton onClick={() => handleClick()} mobile>
          Volver al inicio
        </InicioButton>
      </CardWrapper>
    </>
  );
};

export default ContratosYAnexos;
